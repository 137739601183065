import type {
  CreateMediaUrlOptions,
  DeepExcludeNull,
  Image,
  LegalDisclaimerContent,
  LinkContent,
} from '@smart/aem-utils';
import {
  LegalDisclaimers,
  LinkDestination,
  createId,
  createImage,
  createLegalDisclaimerContents,
  createLinkContent,
  sanitizeGraphqlData,
} from '@smart/aem-utils';
import type {AemContentFragment} from '@smart/website-graphql';
import {z} from 'zod';

export interface ProductTeaserCardItemContent {
  readonly image: Image;
  readonly headline?: string;
  readonly title?: string;
  readonly subTitle?: string;
  readonly highlightValue1?: string;
  readonly highlightLabel1?: string;
  readonly highlightValue2?: string;
  readonly highlightLabel2?: string;
  readonly highlightValue3?: string;
  readonly highlightLabel3?: string;
  readonly richText?: string;
  readonly firstLink?: LinkContent;
  readonly secondLink?: LinkContent;
  readonly price?: string;
  readonly leasingPrice?: string;
}

export interface ProductTeaserCardContent {
  readonly type: 'product-teaser-card';
  readonly id: string;
  readonly anchorId: string;
  readonly teaserList: ProductTeaserCardItemContent[];
  readonly legalDisclaimers: LegalDisclaimerContent[];
  readonly removeTopSpacing?: boolean;
  readonly aspectRatio?: '4-3' | '1-1';
  readonly mboxName?: string;
}

const ProductTeaserCardItemContentFragment = z.object({
  image: z.object({_path: z.string()}),
  imageAltText: z.string().nullish(),
  headline: z.string().nullish(),
  title: z.string().nullish(),
  subTitle: z.string().nullish(),
  highlightValue1: z.string().nullish(),
  highlightLabel1: z.string().nullish(),
  highlightValue2: z.string().nullish(),
  highlightLabel2: z.string().nullish(),
  highlightValue3: z.string().nullish(),
  highlightLabel3: z.string().nullish(),
  price: z.string().nullish(),
  richText: z.object({markdown: z.string().nullish()}).nullish(),
  leasingPrice: z.string().nullish(),
  firstLink: z.string().nullish(),
  firstLinkDestination: LinkDestination.nullish(),
  secondLinkLabel: z.string().nullish(),
  secondLinkDestination: LinkDestination.nullish(),
});

const ProductTeaserCardContentFragment = z.object({
  teaserList: z.array(ProductTeaserCardItemContentFragment),
  legalDisclaimers: LegalDisclaimers.nullish(),
  removeTopSpacing: z.boolean().nullish(),
  aspectRatio: z.string().nullish(),
  mboxName: z.string().nullish(),
});

export function createProductTeaserCardContent(
  contentFragment: Extract<
    AemContentFragment,
    {__typename: 'ProductTeaserCardModel'}
  >,
  options: CreateMediaUrlOptions,
): ProductTeaserCardContent {
  const {
    teaserList,
    legalDisclaimers,
    removeTopSpacing,
    aspectRatio = `4-3`,
  } = sanitizeGraphqlData(ProductTeaserCardContentFragment, contentFragment);

  return {
    type: `product-teaser-card`,
    id: createId(contentFragment),
    anchorId: createId(contentFragment),
    teaserList: createProductTeaserCardItemContents(teaserList, options),
    legalDisclaimers: legalDisclaimers
      ? createLegalDisclaimerContents(legalDisclaimers, options)
      : [],
    removeTopSpacing,
    aspectRatio: aspectRatio as ProductTeaserCardContent['aspectRatio'],
  };
}

function createProductTeaserCardItemContents(
  teaserList: readonly DeepExcludeNull<
    z.TypeOf<typeof ProductTeaserCardItemContentFragment>
  >[],
  options: CreateMediaUrlOptions,
): ProductTeaserCardItemContent[] {
  return teaserList.map(
    ({
      image,
      imageAltText = ``,
      headline,
      title = ``,
      subTitle,
      highlightValue1,
      highlightLabel1,
      highlightValue2,
      highlightLabel2,
      highlightValue3,
      highlightLabel3,
      richText,
      firstLinkDestination,
      firstLink = ``,
      secondLinkDestination,
      secondLinkLabel = ``,
      price,
      leasingPrice,
    }) => ({
      image: createImage({image, imageAltText}, options),
      headline,
      title,
      subTitle,
      highlightValue1,
      highlightLabel1,
      highlightValue2,
      highlightLabel2,
      highlightValue3,
      highlightLabel3,
      richText: richText?.markdown,
      firstLink: createLinkContent({
        destination: firstLinkDestination,
        label: firstLink,
      }),
      secondLink: createLinkContent({
        destination: secondLinkDestination,
        label: secondLinkLabel,
      }),
      price,
      leasingPrice,
    }),
  );
}
