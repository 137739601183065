/* eslint-disable complexity */
import {appMarketConfig} from '@smart/configs';
import {Steps} from '@smart/editorial-components';
import {useFeatureFlags} from '@smart/feature-app';
import {getLanguage} from '@smart/locale-service';
import type {Market} from '@smart/types';
import {getAppMarketConfig} from '@smart/utils';
import type {PageModel} from '@smart/website-page-model';
import * as React from 'react';
import {useState} from 'react';
import {Helmet} from 'react-helmet-async';
import {FEATURE_FLAGS} from '../../types';
import {useEnableChatbot} from '../hooks/use-enable-chatbot';
import {useExtraLanguageContents} from '../hooks/use-extra-language-contents';
import {useFeatureServices} from '../hooks/use-feature-services';
import {useIsStageInViewport} from '../hooks/use-is-stage-in-viewport';
import {LanguageContext} from '../hooks/use-language-context';
import {useNavigationScrolling} from '../hooks/use-navigation-scrolling';
import {usePageModel} from '../hooks/use-page-model';
import {usePageViewTracking} from '../hooks/use-page-view-tracking';
import {usePersonalization} from '../hooks/use-personalization';
import {usePersonalizationTracking} from '../hooks/use-personalization-tracking';
import {usePersonalizedPageModel} from '../hooks/use-personalized-page-model';
import {useUpdateIntegratorContext} from '../hooks/use-update-integrator-context';
import {adobeTargetPersonalizationScript} from '../utils/client/adobe-target-personalisation-script';
import type {MaintenanceMode} from '../utils/server/fetch-maintenance-mode';
import {createRenderKeyGenerator} from '../utils/universal/create-render-key-generator';
import {AccordionWrapper} from './accordion-wrapper';
import {BottomBarFeatureAppLoader} from './bottom-bar-feature-app-loader';
import {CarSlideWrapper} from './car-slide-wrapper';
import {Chatbot} from './chatbot';
import type {DialogRef} from './dialog';
import {ErrorBoundary} from './error-boundary';
import {ExtendedFlexibleGalleryWrapper} from './extended-flexible-gallery-wrapper';
import {ExternalLinkDialog} from './external-link-dialog';
import {FactsAndFiguresWrapper} from './facts-and-figures-wrapper';
import {FeatureAppLoader} from './feature-app-loader';
import {FeatureAppLoaderWithLoadingUi} from './feature-app-loader-with-loading-ui';
import {FeatureAppMaintenanceMessage} from './feature-app-maintenance-message';
import {FlexibleGalleryWrapper} from './flexible-gallery-wrapper';
import {FlexibleTextAndImageWrapper} from './flexible-text-and-image-wrapper';
import {FooterWrapper} from './footer-wrapper';
import {FullWidthTeaserWrapper} from './full-width-teaser-wrapper';
import {HeaderV2Wrapper} from './header-v2-wrapper';
import {HeadlineV2Wrapper} from './headline-v2-wrapper';
import {HeadlineWithImageWrapper} from './headline-width-image-wrapper';
import {HeadlineWithTextWrapper} from './headline-width-text-wrapper';
import {HeroStageWrapper} from './hero-stage-wrapper';
import {ImageStageSmallWrapper} from './image-stage-small-wrapper';
import {ImageStageWrapper} from './image-stage-wrapper';
import {InvalidContentFragmentWarning} from './invalid-content-fragment-warning';
import {LazyContainer} from './lazy-container';
import {LegalDisclaimersWrapper} from './legal-disclaimers-wrapper';
import {LoginErrorToast} from './login-error-toast';
import {MediaStageWrapper} from './media-stage-wrapper';
import {MidBannerWrapper} from './mid-banner-wrapper';
import {NavigationIndicator} from './navigation-indicator';
import {OpenGraphMetaTags} from './open-graph-meta-tags';
import styles from './page.module.scss';
import {PaymentCalculatorFeatureAppLoader} from './payment-calculator-feature-app-loader';
import {ProductTeaserCardWrapper} from './product-teaser-card-wrapper';
import {RichtextWrapper} from './richtext-wrapper';
import {ScrollytellingWrapper} from './scrollytelling-wrapper';
import {StatementWrapper} from './statement-wrapper';
import {TableWrapper} from './table-wrapper';
import {TeaserGroupWrapper} from './teaser-group-wrapper';
import {TeaserRowV2Wrapper} from './teaser-row-v2-wrapper';
import {TeaserRowWithTextWrapper} from './teaser-row-with-text-wrapper';
import {TeaserRowWrapper} from './teaser-row-wrapper';
import {TextParagraphWrapper} from './text-paragraph-wrapper';
import {TextWithMediaWrapper} from './text-with-media-wrapper';
import {TwitterCardMetaTags} from './twitter-card-meta-tags';
import {UspWrapper} from './usp-wrapper';
import {VideoPlayerWrapper} from './video-player-wrapper';
import {VideoStageWrapper} from './video-stage-wrapper';
import {VoiceOfCustomer} from './voice-of-customer';

export interface PageProps {
  readonly initialPageModel: PageModel;
  readonly debug?: boolean;
  readonly maintenanceMode?: MaintenanceMode;
}

const showMaintenanceMode = (
  marketId: string,
  featureApp: string,
  preview: boolean,
  maintenanceMode?: MaintenanceMode,
) => {
  if (!maintenanceMode || !maintenanceMode.enableMaintenanceMode || preview)
    return false;

  const app = maintenanceMode.featureApps.find((a) => a.appName === featureApp);

  if (!app) return false;

  return app.markets.includes(marketId.toUpperCase());
};

export const PageModelContext = React.createContext<PageModel | undefined>(
  undefined,
);

export function Page({
  initialPageModel,
  debug,
  maintenanceMode,
}: PageProps): JSX.Element {
  const {adbContextService, endpointDirectory, historyService} =
    useFeatureServices();

  const {preview, envName} = endpointDirectory;
  const {history} = historyService;
  const [location, setLocation] = useState(history.location);
  const [subMenuActiveKey, setSubMenuActiveKey] = useState(
    history.location.pathname,
  );

  const [heroTemplate, setHeroTemplate] = useState<
    'award' | 'offer' | 'campaign' | 'model' | undefined
  >(undefined);
  const [headerBackgroundColor, setHeaderBackgroundColor] = useState<
    string | undefined
  >(undefined);

  const pageModel = usePageModel(initialPageModel, location);
  const personalization = usePersonalization(pageModel);
  const [isNavigationPending, startNavigation] = React.useTransition();

  React.useEffect(
    () =>
      history.listen(() => {
        startNavigation(() => setLocation(history.location));
        setSubMenuActiveKey(history.location.pathname);
      }),
    [history],
  );

  const personalizedPageModel = usePersonalizedPageModel({
    pageModel,
    personalization,
  });

  useNavigationScrolling(personalizedPageModel);
  useUpdateIntegratorContext(personalizedPageModel);
  usePageViewTracking(personalizedPageModel);
  usePersonalizationTracking(personalization);

  const enableChatbot = useEnableChatbot(personalizedPageModel);

  const {
    pathname,
    locale,
    title,
    description,
    robotsDirectives,
    structuredData,
    alternativeLanguagePages = [],
    googleTag,
    openGraphWebsite,
    twitterCard,
    bannerContent,
    headerV2Content,
    subMenuContent,
    contents,
    footerContent,
    cdnOrigin,
    showBottomBar,
  } = personalizedPageModel;

  const {languageTag, marketId} = locale;

  const [selectedLanguage, setSelectedLanguage] = React.useState<string>(
    getLanguage(languageTag),
  );
  const firstContentElementRef = React.useRef<HTMLElement>(null);
  const isStageInViewport = useIsStageInViewport(
    firstContentElementRef,
    personalizedPageModel,
  );

  const extraLanguageContents = useExtraLanguageContents(
    getLanguage(languageTag),
  );

  const generateRenderKey = createRenderKeyGenerator();

  const externalLinkDialogRef = React.useRef<DialogRef>(null);

  const [isBannerVisible, setIsBannerVisible] = React.useState(false);

  if (debug) {
    return <pre>{JSON.stringify(initialPageModel, null, 2)}</pre>;
  }

  const pageUrl = new URL(pathname, cdnOrigin).href;

  const {enabled: enableVoiceOfCustomerInterruptionPopup} = useFeatureFlags(
    FEATURE_FLAGS.ENABLE_VOICE_OF_CUSTOMER_INTERRUPTION_POPUP,
  );

  const {enabled: enableAdobeTargetPersonalization} = useFeatureFlags(
    FEATURE_FLAGS.ENABLE_ADOBE_TARGET_PERSONALIZATION,
  );

  const marketConfig = getAppMarketConfig(
    marketId.toUpperCase() as Market,
    appMarketConfig,
  );

  return (
    <PageModelContext.Provider value={pageModel}>
      <LanguageContext.Provider value={{selectedLanguage, setSelectedLanguage}}>
        <Helmet>
          {/* TODO suppressHydrationWarning  */}
          <html lang={languageTag} suppressHydrationWarning />
          <title>{title}</title>

          {description && <meta name="description" content={description} />}
          {robotsDirectives && (
            <meta name="robots" content={robotsDirectives} />
          )}
          <link rel="canonical" href={pageUrl} />

          {alternativeLanguagePages.map(({url, languageTag: hrefLang}) => (
            <link
              key={hrefLang}
              rel="alternate"
              hrefLang={hrefLang}
              href={url}
            />
          ))}
          {structuredData && (
            <script type="application/ld+json">
              {JSON.stringify(structuredData, null, 2)}
            </script>
          )}
        </Helmet>
        {enableAdobeTargetPersonalization && (
          <Helmet>
            <script type="text/javascript" data-id="adobe-target">
              {adobeTargetPersonalizationScript}
            </script>
          </Helmet>
        )}
        {Boolean(googleTag) && (
          <Helmet>
            <meta name={googleTag?.name} content={googleTag?.verificationId} />
          </Helmet>
        )}
        {openGraphWebsite && (
          <OpenGraphMetaTags
            openGraphWebsite={openGraphWebsite}
            languageTag={languageTag}
            pageUrl={pageUrl}
          />
        )}
        {twitterCard && <TwitterCardMetaTags twitterCard={twitterCard} />}
        <div className={styles.bannerAndHeader}>
          {headerV2Content && (
            <ErrorBoundary>
              <HeaderV2Wrapper
                key={selectedLanguage}
                content={{
                  ...headerV2Content,
                  subMenuProps: subMenuContent,
                }}
                subMenuActiveKey={subMenuActiveKey}
                bannerContent={bannerContent}
                setIsBannerVisible={setIsBannerVisible}
                heroTemplate={
                  isStageInViewport &&
                  contents.some((v) => v.type === `hero-stage`)
                    ? heroTemplate
                    : undefined
                }
                backgroundColor={
                  isStageInViewport ? headerBackgroundColor : undefined
                }
              />
            </ErrorBoundary>
          )}
        </div>

        <main className={styles.main}>
          {contents.map((content, index) => {
            const key = generateRenderKey(content.id);
            const getContent = () => {
              switch (content.type) {
                case `product-teaser-card`:
                  return <ProductTeaserCardWrapper content={content} />;
                case `feature-app`:
                  return showMaintenanceMode(
                    marketId,
                    content.featureAppName,
                    preview,
                    maintenanceMode,
                  ) ? (
                    <FeatureAppMaintenanceMessage
                      featureAppName={content.featureAppName}
                    />
                  ) : (
                    <LazyContainer initiallyVisible={index < 2}>
                      <ErrorBoundary>
                        <FeatureAppLoaderWithLoadingUi
                          featureAppId={content.id}
                          featureAppName={content.featureAppName}
                          baseUrl={content.baseUrl}
                          src={content.src}
                          config={content.config}
                          isFirstContentOnPage={index === 0}
                          ref={index === 0 ? firstContentElementRef : undefined}
                          isBannerVisible={isBannerVisible}
                        />
                      </ErrorBoundary>
                    </LazyContainer>
                  );
                case `facts-and-figures`:
                  return <FactsAndFiguresWrapper content={content} />;
                case `flexible-gallery`:
                  return <FlexibleGalleryWrapper content={content} />;
                case `extended-flexible-gallery`:
                  return <ExtendedFlexibleGalleryWrapper content={content} />;
                case `flexible-text-and-image`:
                  return <FlexibleTextAndImageWrapper content={content} />;
                case `full-width-teaser`:
                  return <FullWidthTeaserWrapper content={content} />;
                case `headline-with-text`:
                  return <HeadlineWithTextWrapper content={content} />;
                case `headline-with-image`:
                  return <HeadlineWithImageWrapper content={content} />;
                case `hero-stage`:
                  return (
                    <HeroStageWrapper
                      content={content}
                      setHeaderBackground={(template, c) => {
                        setHeroTemplate(template);
                        setHeaderBackgroundColor(c);
                      }}
                      isBannerVisible={isStageInViewport && isBannerVisible}
                      isFirstContentOnPage={index === 0}
                      cdnOrigin={cdnOrigin}
                      ref={index === 0 ? firstContentElementRef : undefined}
                    />
                  );
                case `invalid-content-fragment-content`:
                  return <InvalidContentFragmentWarning content={content} />;
                case `image-stage`:
                  return (
                    <ImageStageWrapper
                      content={content}
                      pathname={pathname}
                      ref={index === 0 ? firstContentElementRef : undefined}
                    />
                  );
                case `image-stage-small`:
                  return (
                    <ImageStageSmallWrapper
                      content={content}
                      ref={index === 0 ? firstContentElementRef : undefined}
                    />
                  );
                case `legal-disclaimers`:
                  return <LegalDisclaimersWrapper content={content} />;
                case `richtext`:
                  return <RichtextWrapper content={content} />;
                case `text-paragraph`:
                  return (
                    <TextParagraphWrapper
                      content={content}
                      cdnOrigin={cdnOrigin}
                    />
                  );
                case `text-with-media`:
                  return (
                    <TextWithMediaWrapper
                      content={content}
                      cdnOrigin={cdnOrigin}
                    />
                  );
                case `steps`:
                  return <Steps envName={envName} fragment={content} />;
                case `teaser-group`:
                  return <TeaserGroupWrapper content={content} />;
                case `teaser-row`:
                  return <TeaserRowWrapper content={content} />;
                case `teaser-row-with-text`:
                  return <TeaserRowWithTextWrapper content={content} />;
                case `teaser-row-v2`:
                  return <TeaserRowV2Wrapper content={content} />;
                case `video-player`:
                  return (
                    <VideoPlayerWrapper
                      content={content}
                      cdnOrigin={cdnOrigin}
                    />
                  );
                case `video-stage`:
                  return (
                    <VideoStageWrapper
                      content={content}
                      cdnOrigin={cdnOrigin}
                      pathname={pathname}
                      ref={index === 0 ? firstContentElementRef : undefined}
                    />
                  );
                case `car-slide`:
                  return (
                    <CarSlideWrapper content={content} cdnOrigin={cdnOrigin} />
                  );
                case `scrollytelling`:
                  return <ScrollytellingWrapper content={content} />;
                case `headline-v2`:
                  return (
                    <HeadlineV2Wrapper
                      content={content}
                      cdnOrigin={cdnOrigin}
                    />
                  );
                case `media-stage`:
                  return (
                    <MediaStageWrapper
                      content={content}
                      cdnOrigin={cdnOrigin}
                    />
                  );
                case `statement`:
                  return <StatementWrapper content={content} />;
                case `table`:
                  return <TableWrapper content={content} />;
                case `accordion`:
                  return (
                    <AccordionWrapper content={content} cdnOrigin={cdnOrigin} />
                  );
                case `usp`:
                  return <UspWrapper content={content} />;
                case `mid-banner`:
                  return <MidBannerWrapper content={content} />;
              }
            };
            return (
              <section key={key} id={content.anchorId}>
                <ErrorBoundary>{getContent()}</ErrorBoundary>
              </section>
            );
          })}
        </main>
        {footerContent && <FooterWrapper content={footerContent} />}
        {showBottomBar && extraLanguageContents?.bottomBarFeatureApp && (
          <ErrorBoundary>
            <BottomBarFeatureAppLoader
              content={extraLanguageContents.bottomBarFeatureApp}
            />
          </ErrorBoundary>
        )}
        {extraLanguageContents?.adbContextIndicatorFeatureApp &&
          adbContextService?.adbContext && (
            <ErrorBoundary>
              <FeatureAppLoader
                featureAppId="adb-context-indicator-feature-app"
                {...extraLanguageContents.adbContextIndicatorFeatureApp}
              />
            </ErrorBoundary>
          )}
        {extraLanguageContents?.paymentCalculatorFeatureApp && (
          <ErrorBoundary>
            <PaymentCalculatorFeatureAppLoader
              content={extraLanguageContents.paymentCalculatorFeatureApp}
            />
          </ErrorBoundary>
        )}
        {extraLanguageContents?.externalLinkPopUp && (
          <ExternalLinkDialog
            content={extraLanguageContents.externalLinkPopUp}
            ref={externalLinkDialogRef}
          />
        )}
        {enableChatbot && <Chatbot locale={locale} />}
        {isNavigationPending && <NavigationIndicator />}
        {extraLanguageContents?.loginErrorToast && (
          <LoginErrorToast content={extraLanguageContents.loginErrorToast} />
        )}
        <VoiceOfCustomer
          isSurveyEnabled={marketConfig.showVoiceOfCustomerSurvey}
          isInterruptionPopupEnabled={
            enableVoiceOfCustomerInterruptionPopup &&
            marketConfig.showVoiceOfCustomerInterruptionPopup
          }
        />
      </LanguageContext.Provider>
    </PageModelContext.Provider>
  );
}
