import type {
  CreateMediaUrlOptions,
  DeepExcludeNull,
  LegalDisclaimerContent,
  LinkContent,
} from '@smart/aem-utils';
import {
  LegalDisclaimers,
  LinkDestination,
  createId,
  createLegalDisclaimerContents,
  createLinkContent,
  createMediaUrl,
  sanitizeGraphqlData,
} from '@smart/aem-utils';
import type {AemContentFragment} from '@smart/website-graphql';
import {z} from 'zod';
import type {BadgesContent} from '../create-badges-content.js';
import {BadgesContentFragment, createBadges} from '../create-badges-content.js';
import type {LogoContainerContent} from '../create-logo-container.js';
import {
  LogoContainerContentFragment,
  createLogoContainer,
} from '../create-logo-container.js';

export type contentTag = {
  label: string;
  variant: 'primary' | 'light' | 'inverted';
};

export type BackgroundColorOption =
  | 'premium #1'
  | 'premium #3'
  | 'brabus'
  | 'pulse'
  | 'pro+'
  | 'pro,pure+,pure';

export type HeroModelHigntlints = {
  readonly rangeLabel?: string;
  readonly rangeValue?: string;
  readonly chargeTimeLabel?: string;
  readonly chargeTimeValue?: string;
  readonly deliveryTimeLabel?: string;
  readonly deliveryTimeValue?: string;
  readonly availableLabel?: string;
  readonly availableValue?: string;
};

export type CommonItem = {
  readonly linkType?: 'link' | 'badge';
  readonly badges?: BadgesContent;
  readonly template?: 'campaign' | 'offer' | 'award' | 'model';
  readonly imageLandscapeUrl?: string;
  readonly imagePortraitUrl?: string;
  readonly videoLandscapeUrl?: string;
  readonly videoPortraitUrl?: string;
  readonly videoPosterImageUrl?: string;
  readonly mediaAltText?: string;
  readonly backgroundColorOption?: BackgroundColorOption;
  readonly fromLabel?: string;
  readonly price?: string;
  readonly subline?: LinkContent | string;
  readonly headline?: string;
  readonly subHeadline?: string;
  readonly primaryLink?: LinkContent;
  readonly logo?: LogoContainerContent;
  readonly secondaryLink?: LinkContent;
  readonly subText?: string;
  readonly contentTag?: contentTag;
  readonly priceRichText?: string;
  readonly logos?: LogoContainerContent[];
  readonly highlights?: HeroModelHigntlints;
};

export type HeroStageItemOffer = {
  readonly template: 'offer';
  readonly backgroundColorOption:
    | 'premium #1'
    | 'premium #3'
    | 'brabus'
    | 'pulse'
    | 'pro+'
    | 'pro,pure+,pure';
} & CommonItem;

export type HeroStageItemAward = {
  readonly template: 'award';
} & CommonItem;

export type HeroStageItemCampaign = {
  readonly template: 'campaign';
} & CommonItem;

export type ModelHeroStageItem = {
  readonly template: 'model';
} & CommonItem;

export type HeroStageItemContent =
  | HeroStageItemAward
  | HeroStageItemCampaign
  | HeroStageItemOffer
  | ModelHeroStageItem;
// TODO: add campaign
export interface HeroStageContent {
  readonly type: 'hero-stage';
  readonly id: string;
  readonly anchorId: string;
  readonly variant?: 'primary' | 'secondary' | 'modelHero';
  readonly modeSelector?: string;
  readonly fullHeight?: boolean;
  readonly showBottomArrowIndicator?: boolean;
  readonly discoverMoreText?: string;
  readonly items: HeroStageItemContent[];
  readonly legalDisclaimers: LegalDisclaimerContent[];
  readonly mboxName?: string;
}

const HeraModelHightlightsContentFragment = z.object({
  rangeLabel: z.string().nullish(),
  rangeValue: z.string().nullish(),
  chargeTimeLabel: z.string().nullish(),
  chargeTimeValue: z.string().nullish(),
  deliveryTimeLabel: z.string().nullish(),
  deliveryTimeValue: z.string().nullish(),
  availableLabel: z.string().nullish(),
  availableValue: z.string().nullish(),
});

const HeroStageItemContentFragment = z.object({
  linkType: z.string().nullish(),
  image: z.object({_path: z.string()}).nullish(),
  imagePortrait: z.object({_path: z.string()}).nullish(),
  videoLandscape: z.object({_path: z.string()}).nullish(),
  videoPortrait: z.object({_path: z.string()}).nullish(),
  videoPosterImage: z.object({_path: z.string()}).nullish(),
  contentTag: z.object({label: z.string(), variant: z.string()}).nullish(),
  logo: LogoContainerContentFragment.nullish(),
  badges: BadgesContentFragment.nullish(),
  backgroundColorOption: z.string().nullish(),
  fromLabel: z.string().nullish(),
  price: z.string().nullish(),
  mediaAltText: z.string().nullish(),
  sublineLinkLabel: z.string().nullish(),
  sublineLinkDestination: LinkDestination.nullish(),
  headline: z.string().nullish(),
  subHeadline: z.string().nullish(),
  primaryLinkLabel: z.string().nullish(),
  primaryLinkDestination: LinkDestination.nullish(),
  secondaryLinkLabel: z.string().nullish(),
  secondaryLinkDestination: LinkDestination.nullish(),
  subText: z.string().nullish(),
  template: z.string().nullish(),
  pricerichtext: z.object({markdown: z.string().nullish()}).nullish(),
  logos: z.array(LogoContainerContentFragment).nullish(),
  highlights: HeraModelHightlightsContentFragment.nullish(),
});

const HeroStageContentFragment = z.object({
  variant: z.string().nullish(),
  modeSelector: z.string().nullish(),
  fullHeight: z.boolean().nullish(),
  showBottomArrowIndicator: z.boolean().nullish(),
  discoverMoreText: z.string().nullish(),
  items: z.array(HeroStageItemContentFragment),
  legalDisclaimers: LegalDisclaimers.nullish(),
  mboxName: z.string().nullish(),
});

export function createHeroStageContent(
  contentFragment: Extract<AemContentFragment, {__typename: 'HeroStageModel'}>,
  options: CreateMediaUrlOptions,
): HeroStageContent {
  const {
    variant,
    modeSelector,
    fullHeight,
    showBottomArrowIndicator,
    discoverMoreText,
    items,
    legalDisclaimers,
    mboxName,
  } = sanitizeGraphqlData(HeroStageContentFragment, contentFragment);

  return {
    type: `hero-stage`,
    id: createId(contentFragment),
    anchorId: createId(contentFragment._path),
    variant: variant as HeroStageContent['variant'],
    modeSelector,
    fullHeight,
    showBottomArrowIndicator,
    discoverMoreText,
    items: createHeroStageItems(items, options),
    legalDisclaimers: legalDisclaimers
      ? createLegalDisclaimerContents(
          legalDisclaimers,
          options as CreateMediaUrlOptions,
        )
      : [],
    mboxName,
  };
}

function createHeroStageItems(
  items: readonly DeepExcludeNull<
    z.TypeOf<typeof HeroStageItemContentFragment>
  >[],
  options: CreateMediaUrlOptions,
): HeroStageItemContent[] {
  return items.map((item) => {
    const {
      linkType,
      image,
      imagePortrait,
      videoLandscape,
      videoPortrait,
      videoPosterImage,
      logo,
      badges,
      mediaAltText,
      sublineLinkLabel,
      sublineLinkDestination,
      headline,
      template,
      price,
      backgroundColorOption,
      fromLabel,
      subHeadline,
      primaryLinkLabel,
      primaryLinkDestination,
      secondaryLinkLabel,
      secondaryLinkDestination,
      subText,
      contentTag,
      pricerichtext,
      highlights,
      logos,
    } = item;

    return {
      linkType:
        linkType != null && linkType !== `null`
          ? (linkType as HeroStageItemContent['linkType'])
          : `link`,
      template: template as HeroStageItemContent['template'],
      price,
      imageLandscapeUrl:
        image != null
          ? createMediaUrl(`image`, image._path, options)
          : undefined,
      imagePortraitUrl:
        imagePortrait != null
          ? createMediaUrl(`image`, imagePortrait._path, options)
          : undefined,
      videoLandscapeUrl:
        videoLandscape != null
          ? createMediaUrl(`content`, videoLandscape._path, options)
          : undefined,
      videoPortraitUrl:
        videoPortrait != null
          ? createMediaUrl(`content`, videoPortrait._path, options)
          : undefined,
      videoPosterImageUrl:
        videoPosterImage != null
          ? createMediaUrl(`image`, videoPosterImage._path, options)
          : undefined,
      mediaAltText,
      subline: sublineLinkDestination
        ? createLinkContent({
            destination: sublineLinkDestination,
            label: sublineLinkLabel,
          })
        : sublineLinkLabel ?? undefined,
      headline,
      subHeadline,
      fromLabel,
      logo: createLogoContainer(logo, options),
      badges: createBadges(badges, options),
      backgroundColorOption: backgroundColorOption as BackgroundColorOption,
      primaryLink: createLinkContent({
        destination: primaryLinkDestination,
        label: primaryLinkLabel,
      }),
      secondaryLink: createLinkContent({
        destination: secondaryLinkDestination,
        label: secondaryLinkLabel,
      }),
      subText,
      contentTag: contentTag as contentTag,
      priceRichText: pricerichtext?.markdown,
      highlights,
      logos: logos
        ? logos.map((logoItem) => {
            return {
              logoDisclaimerReference: logoItem.logoDisclaimerReference,
              url: logoItem.image
                ? createMediaUrl(`image`, logoItem.image._path, options)
                : ``,
            };
          })
        : [],
    };
  });
}
