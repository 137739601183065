import type {
  CreateMediaUrlOptions,
  DeepExcludeNull,
  LegalDisclaimerContent,
  LinkContent,
} from '@smart/aem-utils';
import {
  LegalDisclaimers,
  LinkDestination,
  createId,
  createLegalDisclaimerContents,
  createLinkContent,
  createMediaUrl,
  sanitizeGraphqlData,
} from '@smart/aem-utils';
import type {modeType} from '@smart/editorial-components';
import type {AemContentFragment} from '@smart/website-graphql';
import {z} from 'zod';

export interface TeaserRowV2ItemContent {
  readonly imageUrl: string;
  readonly imageAltText?: string;
  readonly aspectRatio?: '4-3' | '3-4' | '1-1';
  readonly categoryLabel?: string;
  readonly headline?: string;
  readonly copyText?: string;
  readonly ctaType?: 'link' | 'button';
  readonly links: Array<LinkContent>;
}

export interface TeaserRowV2Content {
  readonly type: 'teaser-row-v2';
  readonly id: string;
  readonly anchorId: string;
  readonly removeTopSpacing?: boolean;
  readonly variant:
    | 'side by side'
    | '2 items 80/20'
    | '2 items 20/80'
    | '2 items 50/50'
    | '2 items highlight';
  readonly componentHeadline?: string;
  readonly disableImages: boolean;
  readonly items: TeaserRowV2ItemContent[];
  readonly legalDisclaimers: LegalDisclaimerContent[];
  readonly mboxName?: string;
  readonly modeSelector?: modeType;
}

const TeaserRowV2ItemContentFragment = z.object({
  image: z.object({_path: z.string()}),
  imageAltText: z.string().nullish(),
  aspectRatio: z.string().nullish(),
  categoryLabel: z.string().nullish(),
  headline: z.string().nullish(),
  copyText: z.object({markdown: z.string().nullish()}).nullish(),
  ctaType: z.string().nullish(),
  firstLinkLabel: z.string().nullish(),
  firstLinkDestination: LinkDestination.nullish(),
  secondLinkLabel: z.string().nullish(),
  secondLinkDestination: LinkDestination.nullish(),
  thirdLinkLabel: z.string().nullish(),
  thirdLinkDestination: LinkDestination.nullish(),
});

export const TeaserRowV2ContentFragment = z.object({
  _path: z.string(),
  removeTopSpacing: z.boolean().nullish(),
  variant: z.string(),
  componentHeadline: z.string().nullish(),
  disableImages: z.boolean().nullish(),
  items: z.array(TeaserRowV2ItemContentFragment),
  legalDisclaimer: LegalDisclaimers.nullish(),
  mboxName: z.string().nullish(),
  modeSelector: z.string().nullish(),
});

export function createTeaserRowV2Content(
  contentFragment: Extract<
    AemContentFragment,
    {__typename: 'TeaserRowV2Model'}
  >,
  options: CreateMediaUrlOptions,
): TeaserRowV2Content {
  const {
    removeTopSpacing,
    variant,
    componentHeadline,
    disableImages,
    items,
    legalDisclaimer,
    mboxName,
    modeSelector,
  } = sanitizeGraphqlData(TeaserRowV2ContentFragment, contentFragment);

  return {
    type: `teaser-row-v2`,
    id: createId(contentFragment),
    anchorId: createId(contentFragment._path),
    removeTopSpacing,
    variant: variant as TeaserRowV2Content['variant'],
    componentHeadline,
    disableImages: disableImages || false,
    items: createTeaserRowContents(items, options),
    legalDisclaimers: legalDisclaimer
      ? createLegalDisclaimerContents(legalDisclaimer, options)
      : [],
    mboxName,
    modeSelector: modeSelector as TeaserRowV2Content['modeSelector'],
  };
}

function createTeaserRowContents(
  items: readonly DeepExcludeNull<
    z.TypeOf<typeof TeaserRowV2ItemContentFragment>
  >[],
  options: CreateMediaUrlOptions,
): TeaserRowV2ItemContent[] {
  return items.map(
    ({
      image,
      imageAltText,
      aspectRatio,
      categoryLabel,
      headline,
      ctaType,
      copyText,
      firstLinkLabel,
      firstLinkDestination,
      secondLinkLabel,
      secondLinkDestination,
      thirdLinkLabel,
      thirdLinkDestination,
    }) => {
      const links = [];
      if (firstLinkLabel && firstLinkDestination) {
        links.push(
          createLinkContent({
            label: firstLinkLabel,
            destination: firstLinkDestination,
          })!,
        );
      }
      if (secondLinkLabel && secondLinkDestination) {
        links.push(
          createLinkContent({
            label: secondLinkLabel,
            destination: secondLinkDestination,
          })!,
        );
      }
      if (thirdLinkLabel && thirdLinkDestination) {
        links.push(
          createLinkContent({
            label: thirdLinkLabel,
            destination: thirdLinkDestination,
          })!,
        );
      }
      return {
        imageUrl: createMediaUrl(`image`, image._path, options),
        imageAltText,
        aspectRatio: aspectRatio as TeaserRowV2ItemContent['aspectRatio'],
        categoryLabel,
        headline,
        copyText: copyText?.markdown,
        ctaType: ctaType as TeaserRowV2ItemContent['ctaType'],
        links,
      };
    },
  );
}
