import {TeaserRowV2, type TeaserRowV2Type} from '@smart/editorial-components';
import type {TeaserRowV2Content} from '@smart/website-aem-utils';
import {
  createLegalDisclaimerProps,
  createLinkProps,
} from '@smart/website-aem-utils';
import * as React from 'react';
import {useFeatureServices} from '../hooks/use-feature-services';
import {PageModelContext} from './page';

export interface TeaserRowV2WrapperProps {
  readonly content: TeaserRowV2Content;
}

export const TeaserRowV2Wrapper = React.memo(
  ({content}: TeaserRowV2WrapperProps) => {
    const pageModel = React.useContext(PageModelContext);
    const {languageTag = ``, marketId = ``} = pageModel?.locale || {};

    const {endpointDirectory, pubSubService, loginService, historyService} =
      useFeatureServices();

    const props: TeaserRowV2Type = {
      variant: content.variant,
      removeTopSpacing: content.removeTopSpacing ?? false,
      componentHeadline: content.componentHeadline,
      disableImages: content.disableImages,
      teaserList: content.items.map(
        (
          {
            imageUrl,
            imageAltText,
            categoryLabel,
            headline,
            aspectRatio,
            copyText,
            ctaType,
            links,
          },
          index,
        ) => ({
          image: {imageUrl, imageAltText: imageAltText || ``},
          aspectRatio,
          categoryLabel,
          headline,
          copyText,
          ctaType,
          links: links.map((l) =>
            createLinkProps({
              historyService,
              content: l,
              pubSubService,
              websiteTrackingData: {
                type: `navigation`,
                region: `teaser_row_v2`,
                name: `${l.label}.${index}`,
              },
              envName: endpointDirectory.envName,
              loginState: loginService?.loginState,
              languageTag,
              marketId,
            }),
          ),
          children: null,
        }),
      ),
      legalDisclaimers: createLegalDisclaimerProps(
        content.legalDisclaimers,
        historyService,
      ),
      mode: content.modeSelector,
    };

    return <TeaserRowV2 {...props} />;
  },
  (prevProps, nextProps) => prevProps.content.id === nextProps.content.id,
);

TeaserRowV2Wrapper.displayName = `TeaserRowV2Wrapper`;
